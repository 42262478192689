import {
  addBreadcrumb,
  browserTracingIntegration,
  captureException,
  init,
  setContext,
  setTag,
  setUser,
} from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin(({ $router, vueApp }) => {
  const config = useRuntimeConfig()

  init({
    app: vueApp,
    enabled: !config.public.isDev,
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    debug: config.public.isDev,
    dsn: config.public.sentry.dsn,
    tracePropagationTargets: [config.public.siteUrl],
    integrations: [
      browserTracingIntegration({
        router: $router as Router,
      }),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  return {
    provide: {
      sentry: {
        setContext,
        setUser,
        setTag,
        addBreadcrumb,
        captureException,
      },
    },
  }
})
