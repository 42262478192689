<template>
  <svg
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M9.034 24 9 13.5H4.5V9H9V6c0-4.049 2.507-6 6.12-6 1.73 0 3.216.129 3.65.186v4.232h-2.506c-1.964 0-2.344.934-2.344 2.304V9h5.58L18 13.5h-4.08V24H9.034Z"
    />
  </svg>
</template>
