<template>
  <footer id="about" class="bg-white py-20 md:py-24 xl:py-28">
    <DefaultGrid>
      <div class="col-span-full flex flex-col items-center space-y-10">
        <SvgLogo class="h-[75px] text-white" />
        <div class="type-label flex space-x-7 font-medium">
          <NuxtLink
            v-for="({ label, to, target }, idx) in navLinks"
            :key="idx"
            class="transition-opacity hover:opacity-50"
            :to="to"
            :target="target"
            >{{ label }}</NuxtLink
          >
        </div>
        <div class="flex flex-row space-x-4">
          <SocialButton to="https://www.instagram.com/wait.mate/">
            <SvgGlyphInstagram />
          </SocialButton>
          <SocialButton
            to="https://www.facebook.com/people/Wait-Mate/61559910075995/"
          >
            <SvgGlyphFacebook />
          </SocialButton>
          <SocialButton to="https://www.linkedin.com/company/wait-mate">
            <SvgGlyphLinkedIn />
          </SocialButton>
        </div>
        <span class="text-center text-[14px]/[1.2em] text-black/50">
          &copy;W8M8: Parents for the Responsible Use of Smartphones Inc
          {{ currentYear }}
        </span>
      </div>
    </DefaultGrid>
  </footer>
</template>

<script setup lang="ts">
const navLinks = [
  {
    label: 'Terms',
    to: '/pages/terms',
  },
  {
    label: 'Privacy',
    to: '/pages/privacy',
  },
  {
    label: 'Email Us',
    to: 'mailto:hi@waitmate.org.au',
  },
  {
    label: 'Site by MT',
    to: 'https://www.madetogether.com.au',
    target: '_blank',
  },
]

const currentYear = new Date().getFullYear()
</script>
