<template>
  <NuxtLink
    class="btn btn-black h-12 w-12 rounded-full"
    :to="props.to"
    target="_blank"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
interface SocialButtonProps {
  to: string
}

const props = defineProps<SocialButtonProps>()
</script>
