const _none = "none"
const _transparent = "rgba(0, 0, 0, 0)"
const _current = "currentColor"
const _white = "#FFFFFF"
const _black = "#000000"
const _grey = "#F5F5F5"
const _charcoal = "#313234"
const _red = {"DEFAULT":"#FF2E2E","light":"#FD7474"}
const _pink = {"DEFAULT":"#F69AC6","dark":"#FE76C7"}
const _yellow = {"dark":"#FFB800","DEFAULT":"#FFE400","light":"#FFF500"}
const _mint = {"DEFAULT":"#75F09D","light":"#C8FFDA"}
const _blue = {"DEFAULT":"#79DDDA","dark":"#5AA1DA"}
const _purple = "#708FFF"
const _green = "#5AB679"
const config = { "none": _none, "transparent": _transparent, "current": _current, "white": _white, "black": _black, "grey": _grey, "charcoal": _charcoal, "red": _red, "pink": _pink, "yellow": _yellow, "mint": _mint, "blue": _blue, "purple": _purple, "green": _green,  }
export { config as default, _none, _transparent, _current, _white, _black, _grey, _charcoal, _red, _pink, _yellow, _mint, _blue, _purple, _green }