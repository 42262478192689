import gql from 'graphql-tag';
export const LinkFragmentDoc = gql`
    fragment Link on Link {
  label
  url {
    ... on ExternalUrlBlock {
      id
      blockType
      url
    }
    ... on InternalUrlBlock {
      id
      blockType
      uri
    }
  }
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  description
  width
  height
  blurhash
  sizes {
    sm {
      url
      width
    }
    md {
      url
      width
    }
    lg {
      url
      width
    }
    xl {
      url
      width
    }
    xxl {
      url
      width
    }
    opengraph {
      url
      width
    }
  }
}
    `;
export const NewsArticleFragmentDoc = gql`
    fragment NewsArticle on NewsArticle {
  articleLink
  id
  publicationLogo {
    ...Image
  }
  publishedAt
  title
}
    ${ImageFragmentDoc}`;
export const PageFragmentDoc = gql`
    fragment Page on Page {
  id
  meta {
    description
    image {
      ...Image
    }
    title
  }
  template
}
    ${ImageFragmentDoc}`;
export const ResourceArticleFragmentDoc = gql`
    fragment ResourceArticle on ResourceArticle {
  category {
    name
  }
  content
  id
  image {
    ...Image
  }
  meta {
    description
    image {
      ...Image
    }
    title
  }
  publishedAt
  slug
  title
}
    ${ImageFragmentDoc}`;
export const SchoolFragmentDoc = gql`
    fragment School on School {
  id
  isIndependent
  location {
    city
    postCode
    state
    coordinates
  }
  name
  type
  searchableName
  sector
  url
}
    `;
export const CreateParentDocument = gql`
    mutation CreateParent($data: mutationParentInput!) {
  createParent(data: $data) {
    id
  }
}
    `;
export const CreateStudentDocument = gql`
    mutation CreateStudent($data: mutationStudentInput!) {
  createStudent(data: $data) {
    id
    graduationYear
    primarySchool {
      id
    }
  }
}
    `;
export const UpdateEmailPreferencesDocument = gql`
    mutation UpdateEmailPreferences($parentID: String!, $email: String!, $emailOptOut: Boolean, $groupOptOut: Boolean) {
  updateEmailPreferences(
    parentID: $parentID
    email: $email
    emailOptOut: $emailOptOut
    groupOptOut: $groupOptOut
  ) {
    emailOptOut
    groupOptOut
  }
}
    `;
export const GetCohortStatsDocument = gql`
    query GetCohortStats($schoolID: Int!, $graduationYear: Int!) {
  CohortStats(schoolID: $schoolID, graduationYear: $graduationYear) {
    graduationYear
    school {
      ...School
    }
    studentCount
  }
}
    ${SchoolFragmentDoc}`;
export const GetEmailPreferencesDocument = gql`
    query GetEmailPreferences($parentID: String!, $email: String!) {
  EmailPreferences(parentID: $parentID, email: $email) {
    emailOptOut
    groupOptOut
  }
}
    `;
export const GetGlobalsDocument = gql`
    query GetGlobals {
  Settings {
    meta {
      title
      description
      image {
        ...Image
      }
    }
    pledge {
      cohortCard {
        heading {
          firstSignUp
          multipleSignUps
          closeToThreshold
          pledgeUnlocked
        }
        progress {
          pledgeUnlocked
        }
        sharing {
          firstSignUp
          multipleSignUps
          pledgeUnlocked
        }
      }
      heading
      whatHappensNext {
        afterUnlock
        beforeUnlock
      }
    }
  }
}
    ${ImageFragmentDoc}`;
export const GetImageDocument = gql`
    query GetImage($id: String!) {
  Image(id: $id) {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const GetNewsArticlesDocument = gql`
    query GetNewsArticles($limit: Int, $page: Int) {
  NewsArticles(limit: $limit, page: $page, sort: "-publishedAt") {
    docs {
      ...NewsArticle
    }
    page
    limit
    totalPages
  }
}
    ${NewsArticleFragmentDoc}`;
export const GetPageDocument = gql`
    query GetPage($template: Page_template_Input) {
  Pages(where: {template: {equals: $template}}) {
    docs {
      ...Page
      aboutFields {
        header {
          heading
          image {
            ...Image
          }
        }
        intro {
          body
          supporterLogos {
            logo {
              ...Image
            }
          }
        }
        carousel {
          natureImage {
            ...Image
          }
          friendsImage {
            ...Image
          }
          hobbiesImage {
            ...Image
          }
        }
      }
      homeFields {
        intro {
          body
          ctaButton {
            ...Link
          }
          image {
            ...Image
          }
        }
        whySection {
          heading
          cards {
            heading
            largeText
            body
          }
          ctaButton {
            ...Link
          }
          carousel {
            image {
              ...Image
            }
          }
        }
        joinSection {
          heading
          body
          stepCards {
            icon
            heading
            body
          }
        }
      }
      resourcesFields {
        filters {
          id
          name
        }
      }
      whyWaitFields {
        intro {
          body
        }
        cards {
          heading
          largeText
          body
        }
      }
    }
  }
}
    ${PageFragmentDoc}
${ImageFragmentDoc}
${LinkFragmentDoc}`;
export const GetResourceArticleBySlugDocument = gql`
    query GetResourceArticleBySlug($slug: String!) {
  ResourceArticles(where: {slug: {equals: $slug}, _status: {equals: published}}) {
    docs {
      ...ResourceArticle
    }
  }
}
    ${ResourceArticleFragmentDoc}`;
export const GetResourceArticlesDocument = gql`
    query GetResourceArticles($limit: Int, $page: Int, $categoryID: JSON) {
  ResourceArticles(
    limit: $limit
    page: $page
    sort: "-publishedAt"
    where: {AND: [{category: {equals: $categoryID}}, {_status: {equals: published}}]}
  ) {
    docs {
      ...ResourceArticle
    }
    page
    limit
    totalPages
  }
}
    ${ResourceArticleFragmentDoc}`;
export const GetSchoolsSortedDocument = gql`
    query GetSchoolsSorted($limit: Int, $query: String!, $type: [School_type_Input]) {
  SchoolsSorted(query: $query, limit: $limit, where: {type: {in: $type}}) {
    docs {
      ...School
    }
  }
}
    ${SchoolFragmentDoc}`;
export const GetStaticPageDocument = gql`
    query GetStaticPage($slug: String!) {
  StaticPages(where: {slug: {equals: $slug}}) {
    docs {
      content
      id
      intro
      title
      updatedAt
    }
  }
}
    `;