<template>
  <Component :is="IconComponents[props.icon]" class="w-auto" />
</template>

<script setup lang="ts">
interface PayloadIconProps {
  icon:
    | 'SvgIconAlarmClock'
    | 'SvgIconAngerA'
    | 'SvgIconAngerB'
    | 'SvgIconAngerC'
    | 'SvgIconArrow'
    | 'SvgIconAttentionA'
    | 'SvgIconAttentionB'
    | 'SvgIconAttentionC'
    | 'SvgIconAttentionD'
    | 'SvgIconAttentionE'
    | 'SvgIconAttentionF'
    | 'SvgIconAttentionG'
    | 'SvgIconAttentionH'
    | 'SvgIconBasketball'
    | 'SvgIconBlob'
    | 'SvgIconBook'
    | 'SvgIconBouncingTennisBall'
    | 'SvgIconBranch'
    | 'SvgIconChatBubbles'
    | 'SvgIconCloud'
    | 'SvgIconConfusionA'
    | 'SvgIconConfusionB'
    | 'SvgIconConfusionC'
    | 'SvgIconConfusionD'
    | 'SvgIconConfusionE'
    | 'SvgIconCoolS'
    | 'SvgIconDreamingA'
    | 'SvgIconDreamingB'
    | 'SvgIconEarth'
    | 'SvgIconElectricGuitar'
    | 'SvgIconEmbarrassed'
    | 'SvgIconExplosion'
    | 'SvgIconFirework'
    | 'SvgIconFish'
    | 'SvgIconFlower'
    | 'SvgIconFlowerAlt'
    | 'SvgIconFlowerPetals'
    | 'SvgIconFlowerPetalsAlt'
    | 'SvgIconFrustrationA'
    | 'SvgIconFrustrationB'
    | 'SvgIconFrustrationC'
    | 'SvgIconFrustrationD'
    | 'SvgIconFrustrationE'
    | 'SvgIconGuitar'
    | 'SvgIconHeart'
    | 'SvgIconHeartAlt'
    | 'SvgIconHeartEyesSmiley'
    | 'SvgIconInstagram'
    | 'SvgIconLeaf'
    | 'SvgIconLeaves'
    | 'SvgIconLightning'
    | 'SvgIconLightningBolts'
    | 'SvgIconLike'
    | 'SvgIconLipstick'
    | 'SvgIconLol'
    | 'SvgIconMapleLeaf'
    | 'SvgIconMicrophone'
    | 'SvgIconNeglected'
    | 'SvgIconNotification'
    | 'SvgIconOkHand'
    | 'SvgIconPeaceHand'
    | 'SvgIconPencil'
    | 'SvgIconPoo'
    | 'SvgIconRisingSun'
    | 'SvgIconSaturn'
    | 'SvgIconShockA'
    | 'SvgIconShockB'
    | 'SvgIconShockC'
    | 'SvgIconShockD'
    | 'SvgIconShockE'
    | 'SvgIconShootingStar'
    | 'SvgIconSkateboard'
    | 'SvgIconSmiley'
    | 'SvgIconSnapchat'
    | 'SvgIconStar'
    | 'SvgIconSun'
    | 'SvgIconSunRise'
    | 'SvgIconSurfboard'
    | 'SvgIconSurfboardAlt'
    | 'SvgIconTennisBall'
    | 'SvgIconThinking'
    | 'SvgIconThoughtBubble'
    | 'SvgIconThreeHearts'
    | 'SvgIconTwoHearts'
    | 'SvgIconTypingIndicator'
    | 'SvgIconWaitMate'
    | 'SvgIconWave'
}

const props = defineProps<PayloadIconProps>()

const IconComponents = {
  SvgIconAlarmClock: resolveComponent('SvgIconAlarmClock'),
  SvgIconAngerA: resolveComponent('SvgIconAngerA'),
  SvgIconAngerB: resolveComponent('SvgIconAngerB'),
  SvgIconAngerC: resolveComponent('SvgIconAngerC'),
  SvgIconArrow: resolveComponent('SvgIconArrow'),
  SvgIconAttentionA: resolveComponent('SvgIconAttentionA'),
  SvgIconAttentionB: resolveComponent('SvgIconAttentionB'),
  SvgIconAttentionC: resolveComponent('SvgIconAttentionC'),
  SvgIconAttentionD: resolveComponent('SvgIconAttentionD'),
  SvgIconAttentionE: resolveComponent('SvgIconAttentionE'),
  SvgIconAttentionF: resolveComponent('SvgIconAttentionF'),
  SvgIconAttentionG: resolveComponent('SvgIconAttentionG'),
  SvgIconAttentionH: resolveComponent('SvgIconAttentionH'),
  SvgIconBasketball: resolveComponent('SvgIconBasketball'),
  SvgIconBlob: resolveComponent('SvgIconBlob'),
  SvgIconBook: resolveComponent('SvgIconBook'),
  SvgIconBouncingTennisBall: resolveComponent('SvgIconBouncingTennisBall'),
  SvgIconBranch: resolveComponent('SvgIconBranch'),
  SvgIconChatBubbles: resolveComponent('SvgIconChatBubbles'),
  SvgIconCloud: resolveComponent('SvgIconCloud'),
  SvgIconConfusionA: resolveComponent('SvgIconConfusionA'),
  SvgIconConfusionB: resolveComponent('SvgIconConfusionB'),
  SvgIconConfusionC: resolveComponent('SvgIconConfusionC'),
  SvgIconConfusionD: resolveComponent('SvgIconConfusionD'),
  SvgIconConfusionE: resolveComponent('SvgIconConfusionE'),
  SvgIconCoolS: resolveComponent('SvgIconCoolS'),
  SvgIconDreamingA: resolveComponent('SvgIconDreamingA'),
  SvgIconDreamingB: resolveComponent('SvgIconDreamingB'),
  SvgIconEarth: resolveComponent('SvgIconEarth'),
  SvgIconElectricGuitar: resolveComponent('SvgIconElectricGuitar'),
  SvgIconEmbarrassed: resolveComponent('SvgIconEmbarrassed'),
  SvgIconExplosion: resolveComponent('SvgIconExplosion'),
  SvgIconFirework: resolveComponent('SvgIconFirework'),
  SvgIconFish: resolveComponent('SvgIconFish'),
  SvgIconFlower: resolveComponent('SvgIconFlower'),
  SvgIconFlowerAlt: resolveComponent('SvgIconFlowerAlt'),
  SvgIconFlowerPetals: resolveComponent('SvgIconFlowerPetals'),
  SvgIconFlowerPetalsAlt: resolveComponent('SvgIconFlowerPetalsAlt'),
  SvgIconFrustrationA: resolveComponent('SvgIconFrustrationA'),
  SvgIconFrustrationB: resolveComponent('SvgIconFrustrationB'),
  SvgIconFrustrationC: resolveComponent('SvgIconFrustrationC'),
  SvgIconFrustrationD: resolveComponent('SvgIconFrustrationD'),
  SvgIconFrustrationE: resolveComponent('SvgIconFrustrationE'),
  SvgIconGuitar: resolveComponent('SvgIconGuitar'),
  SvgIconHeart: resolveComponent('SvgIconHeart'),
  SvgIconHeartAlt: resolveComponent('SvgIconHeartAlt'),
  SvgIconHeartEyesSmiley: resolveComponent('SvgIconHeartEyesSmiley'),
  SvgIconInstagram: resolveComponent('SvgIconInstagram'),
  SvgIconLeaf: resolveComponent('SvgIconLeaf'),
  SvgIconLeaves: resolveComponent('SvgIconLeaves'),
  SvgIconLightning: resolveComponent('SvgIconLightning'),
  SvgIconLightningBolts: resolveComponent('SvgIconLightningBolts'),
  SvgIconLike: resolveComponent('SvgIconLike'),
  SvgIconLipstick: resolveComponent('SvgIconLipstick'),
  SvgIconLol: resolveComponent('SvgIconLol'),
  SvgIconMapleLeaf: resolveComponent('SvgIconMapleLeaf'),
  SvgIconMicrophone: resolveComponent('SvgIconMicrophone'),
  SvgIconNeglected: resolveComponent('SvgIconNeglected'),
  SvgIconNotification: resolveComponent('SvgIconNotification'),
  SvgIconOkHand: resolveComponent('SvgIconOkHand'),
  SvgIconPeaceHand: resolveComponent('SvgIconPeaceHand'),
  SvgIconPencil: resolveComponent('SvgIconPencil'),
  SvgIconPoo: resolveComponent('SvgIconPoo'),
  SvgIconRisingSun: resolveComponent('SvgIconRisingSun'),
  SvgIconSaturn: resolveComponent('SvgIconSaturn'),
  SvgIconShockA: resolveComponent('SvgIconShockA'),
  SvgIconShockB: resolveComponent('SvgIconShockB'),
  SvgIconShockC: resolveComponent('SvgIconShockC'),
  SvgIconShockD: resolveComponent('SvgIconShockD'),
  SvgIconShockE: resolveComponent('SvgIconShockE'),
  SvgIconShootingStar: resolveComponent('SvgIconShootingStar'),
  SvgIconSkateboard: resolveComponent('SvgIconSkateboard'),
  SvgIconSmiley: resolveComponent('SvgIconSmiley'),
  SvgIconSnapchat: resolveComponent('SvgIconSnapchat'),
  SvgIconStar: resolveComponent('SvgIconStar'),
  SvgIconSun: resolveComponent('SvgIconSun'),
  SvgIconSunRise: resolveComponent('SvgIconSunRise'),
  SvgIconSurfboard: resolveComponent('SvgIconSurfboard'),
  SvgIconSurfboardAlt: resolveComponent('SvgIconSurfboardAlt'),
  SvgIconTennisBall: resolveComponent('SvgIconTennisBall'),
  SvgIconThinking: resolveComponent('SvgIconThinking'),
  SvgIconThoughtBubble: resolveComponent('SvgIconThoughtBubble'),
  SvgIconThreeHearts: resolveComponent('SvgIconThreeHearts'),
  SvgIconTwoHearts: resolveComponent('SvgIconTwoHearts'),
  SvgIconTypingIndicator: resolveComponent('SvgIconTypingIndicator'),
  SvgIconWaitMate: resolveComponent('SvgIconWaitMate'),
  SvgIconWave: resolveComponent('SvgIconWave'),
}
</script>
