const _none = "0px"
const _sm = "0.125rem"
const _DEFAULT = "0.25rem"
const _md = "0.375rem"
const _lg = "0.5rem"
const _xl = "0.625rem"
const _2xl = "0.875rem"
const _3xl = "1.25rem"
const _full = "9999px"
const config = { "none": _none, "sm": _sm, "DEFAULT": _DEFAULT, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "full": _full,  }
export { config as default, _none, _sm, _DEFAULT, _md, _lg, _xl, _2xl, _3xl, _full }