import { defineStore } from 'pinia'

import type { Settings } from '#payload/types'
import { GetGlobalsDocument } from '@/graphql'

interface GlobalQueryResult {
  Settings: Settings
}

export const useGlobalsStore = defineStore('globals', () => {
  const cardBackgroundClasses = ['bg-mint', 'bg-pink', 'bg-yellow', 'bg-blue']
  const getCardBackgroundClass = (index: number) =>
    cardBackgroundClasses[index % cardBackgroundClasses.length]

  const settings = ref<Settings | null>(null)

  function load() {
    return useAsyncQuery<GlobalQueryResult>(GetGlobalsDocument).then(
      ({ data }) => {
        settings.value = data.value?.Settings || null
      },
    )
  }

  return {
    cardBackgroundClasses,
    getCardBackgroundClass,
    settings,
    load,
  }
})
