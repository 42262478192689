<template>
  <div class="relative inline-block font-medium">
    <h1 class="type-font-salt-ss02 -rotate-[10deg] text-center text-charcoal">
      error!
    </h1>
    <div class="pointer-events-none absolute inset-0">
      <PayloadIcon
        class="absolute bottom-[0.65em] left-[-0.25em] h-[0.33em] -rotate-[20deg] text-yellow"
        icon="SvgIconShockA"
      />
      <PayloadIcon
        class="absolute bottom-[1.1em] left-[1.4em] h-[0.35em] text-green"
        icon="SvgIconFrustrationA"
      />
      <PayloadIcon
        class="absolute bottom-[0.2em] left-[2.6em] h-[0.4em] rotate-[20deg] text-pink-dark"
        icon="SvgIconPoo"
      />

      <PayloadIcon
        class="absolute -bottom-[0.1em] left-[1.6em] h-[0.35em] rotate-[170deg] text-blue-dark"
        icon="SvgIconLike"
      />
    </div>
  </div>
</template>
