<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="black" />
    <path
      d="M34.2234 12H13.7719C12.7922 12 12 12.7734 12 13.7297V34.2656C12 35.2219 12.7922 36 13.7719 36H34.2234C35.2031 36 36 35.2219 36 34.2703V13.7297C36 12.7734 35.2031 12 34.2234 12ZM19.1203 32.4516H15.5578V20.9953H19.1203V32.4516ZM17.3391 19.4344C16.1953 19.4344 15.2719 18.5109 15.2719 17.3719C15.2719 16.2328 16.1953 15.3094 17.3391 15.3094C18.4781 15.3094 19.4016 16.2328 19.4016 17.3719C19.4016 18.5062 18.4781 19.4344 17.3391 19.4344ZM32.4516 32.4516H28.8937V26.8828C28.8937 25.5563 28.8703 23.8453 27.0422 23.8453C25.1906 23.8453 24.9094 25.2938 24.9094 26.7891V32.4516H21.3563V20.9953H24.7687V22.5609H24.8156C25.2891 21.6609 26.4516 20.7094 28.1813 20.7094C31.7859 20.7094 32.4516 23.0813 32.4516 26.1656V32.4516V32.4516Z"
      fill="white"
    />
  </svg>
</template>
