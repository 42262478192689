<template>
  <NuxtLayout class="font-body">
    <PageContainer class="h-screen bg-mint" hide-footer>
      <template #header>
        <div class="flex h-full items-center">
          <DefaultGrid class="w-full">
            <div class="col-span-full flex flex-col items-center">
              <LockupError class="mb-[0.3em] text-[120px] md:text-[220px]" />
              <div class="flex flex-col items-center space-y-8">
                <p
                  class="type-body max-w-3xl whitespace-normal"
                  v-text="error.message"
                />
                <BaseButton class="btn-sm btn-black" @click="handleError">
                  Back to Home
                </BaseButton>
              </div>
            </div>
          </DefaultGrid>
        </div>
      </template>
    </PageContainer>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { H3Error } from 'h3'

interface Props {
  error: H3Error
}
const props = defineProps<Props>()

const handleError = () => clearError({ redirect: '/' })
</script>
