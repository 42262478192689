<template>
  
</template>

<script setup lang="ts">
const show = ref(false)
const controlState = useKeyModifier('Control')
const { md, lg } = useTailwindBreakpoints()

const columnCount = computed(() => {
  if (lg.value) {
    return 12
  } else if (md.value) {
    return 8
  } else {
    return 4
  }
})

onKeyStroke('g', () => {
  if (controlState.value) {
    show.value = !show.value
  }
})
</script>

<style scoped lang="postcss">
.vertical-grid {
  background-image: url("data:image/svg+xml,%3Csvg width='1' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23F00' d='M0 0h1v4H0z'/%3E%3C/svg%3E");
}
</style>
