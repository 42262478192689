<template>
  <div class="flex min-h-screen flex-col">
    <div class="flex w-full flex-grow flex-col">
      <header
        v-if="$slots.header"
        class="w-full pt-32 md:pt-36"
        :class="$slots.header && !$slots.main ? 'flex-grow' : ''"
      >
        <slot name="header" />
      </header>
      <main
        v-if="$slots.main"
        class="w-full"
        :class="$slots.main && !$slots.header ? 'flex-grow' : ''"
      >
        <slot name="main" />
      </main>
    </div>
    <PageFooter v-if="!props.hideFooter" class="w-full" />
  </div>
</template>

<script setup lang="ts">
interface PageContainerProps {
  hideFooter?: boolean
}

const props = withDefaults(defineProps<PageContainerProps>(), {
  hideFooter: false,
})
</script>
