<template>
  <button :disabled="props.disabled" :type="props.type" class="btn">
    <slot />
  </button>
</template>

<script setup lang="ts">
import type { BaseButtonProps } from './types'

const props = withDefaults(defineProps<BaseButtonProps>(), {
  disabled: false,
  type: 'button',
})
</script>
