import revive_payload_client_FqWgzVYTCV from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_B1R0ZM4LMo from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TE5tTaWEj5 from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fKRpr3sXAl from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.9_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_sas_y2olbf6lxrv7oyyhmr2z7a52jq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_3eKFjyG4Xj from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_wevN5B1LGV from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ApuEhqUlYs from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pxx3Df5XzR from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_C0XZhyecd1 from "/var/www/wait-mate-prod/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.31_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/wait-mate-prod/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_vseqlfpGVx from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_iaqjlo2uv4jtmhpkvca5v67e5q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ACCnm9n6Zw from "/var/www/wait-mate-prod/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+_6trvhrlnefhln4qrrty4yxyzoy/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_ic666vW1sy from "/var/www/wait-mate-prod/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_@lezer+common@1.2.1_@nuxt+devtools@1.3.9_rollup@4.18.0_vite@5.3.2_@typ_hquzponafqh6xjudnkhlduko4y/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_4p4tqrPMps from "/var/www/wait-mate-prod/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_@lezer+common@1.2.1_@nuxt+devtools@1.3.9_rollup@4.18.0_vite@5.3.2_@typ_hquzponafqh6xjudnkhlduko4y/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_AkxPmIyrpA from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.9_rollup@4.18.0_vite@5.3.2_@types+node@20._3twc5ladh7odjfr65cczbe5zq4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_L4pq2Btv7Z from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.9_rollup@4.18.0_vite@5.3.2_@types+node@20._3twc5ladh7odjfr65cczbe5zq4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_0RiatPbTR3 from "/var/www/wait-mate-prod/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1_3yuyldnbecgprllxtgm4o62z6e/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import anime_client_02GKJVROBY from "/var/www/wait-mate-prod/apps/nuxt/plugins/anime.client.ts";
import dayjs_pGiXRjcsJO from "/var/www/wait-mate-prod/apps/nuxt/plugins/dayjs.ts";
import maska_UHaKf2z1iQ from "/var/www/wait-mate-prod/apps/nuxt/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/var/www/wait-mate-prod/apps/nuxt/plugins/sentry.client.ts";
import vue_select_8ji40gexSh from "/var/www/wait-mate-prod/apps/nuxt/plugins/vue-select.ts";
export default [
  revive_payload_client_FqWgzVYTCV,
  unhead_B1R0ZM4LMo,
  router_TE5tTaWEj5,
  _0_siteConfig_fKRpr3sXAl,
  payload_client_3eKFjyG4Xj,
  navigation_repaint_client_wevN5B1LGV,
  check_outdated_build_client_ApuEhqUlYs,
  chunk_reload_client_pxx3Df5XzR,
  plugin_vue3_C0XZhyecd1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vseqlfpGVx,
  plugin_ACCnm9n6Zw,
  titles_ic666vW1sy,
  defaults_4p4tqrPMps,
  siteConfig_AkxPmIyrpA,
  inferSeoMetaPlugin_L4pq2Btv7Z,
  plugin_0RiatPbTR3,
  anime_client_02GKJVROBY,
  dayjs_pGiXRjcsJO,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  vue_select_8ji40gexSh
]