<template>
  <svg
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M12 2.162c3.204 0 3.584.013 4.85.07.76.01 1.514.15 2.227.414.522.193.993.5 1.38.9.4.387.707.858.9 1.38.265.713.405 1.467.414 2.228.057 1.265.07 1.65.07 4.849 0 3.2-.013 3.583-.07 4.85-.01.76-.15 1.514-.414 2.227a3.966 3.966 0 0 1-2.277 2.277 6.627 6.627 0 0 1-2.227.414c-1.267.057-1.65.07-4.85.07-3.2 0-3.584-.013-4.85-.07a6.628 6.628 0 0 1-2.227-.414 3.708 3.708 0 0 1-1.38-.9 3.708 3.708 0 0 1-.9-1.38 6.628 6.628 0 0 1-.414-2.228c-.057-1.265-.07-1.65-.07-4.849 0-3.2.013-3.584.07-4.85.01-.76.15-1.514.414-2.227.193-.522.5-.993.9-1.38.387-.4.858-.707 1.38-.9a6.628 6.628 0 0 1 2.228-.414c1.265-.057 1.65-.07 4.849-.07M12 0C8.74 0 8.332.013 7.05.072A8.802 8.802 0 0 0 4.14.63c-.801.3-1.527.773-2.125 1.385A5.862 5.862 0 0 0 .63 4.14a8.802 8.802 0 0 0-.558 2.91C.014 8.332 0 8.74 0 12c0 3.26.013 3.668.072 4.95.02.995.209 1.979.558 2.91.3.801.773 1.527 1.385 2.125A5.861 5.861 0 0 0 4.14 23.37c.932.35 1.917.538 2.913.558C8.333 23.986 8.741 24 12 24c3.26 0 3.668-.014 4.95-.072a8.803 8.803 0 0 0 2.913-.558 6.143 6.143 0 0 0 3.51-3.51c.35-.932.538-1.917.558-2.913.058-1.28.072-1.688.072-4.95 0-3.262-.014-3.668-.072-4.95a8.801 8.801 0 0 0-.561-2.907 5.861 5.861 0 0 0-1.385-2.125A5.862 5.862 0 0 0 19.86.63a8.802 8.802 0 0 0-2.91-.558C15.668.014 15.26 0 12 0Z"
    />
    <path
      d="M12.002 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324Zm0 10.162a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
    />
    <path d="M18.405 7.035a1.44 1.44 0 1 0 0-2.88 1.44 1.44 0 0 0 0 2.88Z" />
  </svg>
</template>
