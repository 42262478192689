<template>
  <svg
    class="transition-colors duration-500"
    width="77"
    height="105"
    viewBox="0 0 77 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#000"
      d="M23.224 0H2.13C.956 0 0 .934 0 2.085v100.828c0 1.15.956 2.085 2.131 2.085h21.093c1.178 0 2.131-.935 2.131-2.085V2.085C25.355.932 24.402 0 23.224 0Z"
    />
    <path
      fill="#000"
      d="M73.784 0H52.69c-1.178 0-2.131.934-2.131 2.085v100.828c0 1.15.953 2.085 2.131 2.085h21.093c1.178 0 2.131-.935 2.131-2.085V2.085C75.915.932 74.959 0 73.783 0Z"
    />
    <g class="fill-current">
      <path
        d="M6.686 7.873c0-.23.187-.414.416-.414h2.182c.229 0 .416.187.416.416v7.57c0 .452.62.578.798.164l.836-1.961a.415.415 0 0 1 .382-.252h1.64c.166 0 .315.098.382.25l.836 1.94c.178.414.796.288.796-.164V7.877c0-.229.187-.416.416-.416h2.205c.229 0 .416.187.416.416v14.788a.417.417 0 0 1-.416.416h-2.86a.414.414 0 0 1-.38-.25l-1.83-4.204a.416.416 0 0 0-.762 0l-1.81 4.202a.417.417 0 0 1-.382.25H7.085a.417.417 0 0 1-.415-.416l.02-14.788-.004-.002Z"
      />
      <path
        d="M18.688 84.306a.417.417 0 0 1-.416.416h-3.52a.417.417 0 0 0-.415.416v11.798a.417.417 0 0 1-.416.416h-2.384a.415.415 0 0 1-.415-.416V85.138a.417.417 0 0 0-.416-.416h-3.52a.415.415 0 0 1-.416-.415v-2.16c0-.228.187-.415.416-.415H18.27c.229 0 .416.185.416.416v2.159h.002Z"
      />
      <path
        d="M11.126 59.74V57.39c0-.229.187-.416.416-.416h2.383c.23 0 .416.185.416.416v14.788a.417.417 0 0 1-.416.416h-2.383a.415.415 0 0 1-.416-.416V59.741Z"
      />
      <path
        d="M13.98 41.322a.415.415 0 0 1-.4.533h-1.698a.416.416 0 0 1-.4-.533l.851-2.873a.415.415 0 0 1 .796 0l.85 2.873Zm4.897 5.974-4.605-14.788a.415.415 0 0 0-.397-.292h-2.268a.417.417 0 0 0-.397.292L6.583 47.296a.416.416 0 0 0 .397.54h2.273a.415.415 0 0 0 .396-.296l.74-2.484a.414.414 0 0 1 .397-.296h3.91c.183 0 .346.12.397.296l.739 2.484a.414.414 0 0 0 .397.296h2.249c.28 0 .479-.27.397-.54h.002Z"
      />
      <path
        d="M57.345 7.984c0-.229.184-.416.416-.416h2.883c.166 0 .315.1.38.25l1.844 4.24a.416.416 0 0 0 .762 0l1.825-4.236a.417.417 0 0 1 .382-.25h2.904c.23 0 .416.187.416.416l-.02 14.892a.415.415 0 0 1-.417.413h-2.202a.417.417 0 0 1-.416-.416v-7.635c0-.452-.62-.578-.798-.164l-.847 1.987a.415.415 0 0 1-.382.252h-1.652a.419.419 0 0 1-.383-.25l-.846-1.966c-.178-.414-.796-.288-.796.164v7.612a.415.415 0 0 1-.416.416h-2.224a.417.417 0 0 1-.415-.416V7.984h.002Z"
      />
      <path
        d="M68.349 84.35a.415.415 0 0 1-.416.416h-5.998a.415.415 0 0 0-.416.416v2.65c0 .23.185.416.416.416h5.032c.229 0 .416.185.416.416v2a.415.415 0 0 1-.416.415h-5.032a.415.415 0 0 0-.416.416v2.74c0 .23.185.416.416.416h5.998c.229 0 .416.185.416.416v2.09a.415.415 0 0 1-.416.415H58.7a.415.415 0 0 1-.416-.416v-14.89c0-.23.185-.416.416-.416h9.232c.229 0 .416.184.416.415v2.09-.004Z"
      />
      <path
        d="M69.248 59.682a.415.415 0 0 1-.416.416h-3.55a.415.415 0 0 0-.415.416v11.881a.415.415 0 0 1-.416.416h-2.404a.415.415 0 0 1-.416-.416V60.514a.415.415 0 0 0-.416-.416h-3.549a.415.415 0 0 1-.416-.416v-2.18c0-.229.185-.416.416-.416h11.162c.228 0 .415.185.415.416v2.18h.005Z"
      />
      <path
        d="M69.042 48.05h-2.27a.414.414 0 0 1-.397-.296l-.746-2.505a.414.414 0 0 0-.397-.296h-3.94a.414.414 0 0 0-.396.296l-.746 2.505a.414.414 0 0 1-.396.296H57.46a.417.417 0 0 1-.397-.54l4.658-14.89a.417.417 0 0 1 .397-.292h2.287c.183 0 .342.117.397.291l4.637 14.892a.416.416 0 0 1-.397.54Zm-5.393-9.458a.415.415 0 0 0-.796 0l-.859 2.904a.415.415 0 0 0 .4.534h1.717a.416.416 0 0 0 .399-.534l-.859-2.904h-.002Z"
      />
    </g>
  </svg>
</template>
